import { render, staticRenderFns } from "./spHeader.vue?vue&type=template&id=1cbcb8f7&scoped=true&"
import script from "./spHeader.vue?vue&type=script&lang=js&"
export * from "./spHeader.vue?vue&type=script&lang=js&"
import style0 from "./spHeader.vue?vue&type=style&index=0&id=1cbcb8f7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cbcb8f7",
  null
  
)

export default component.exports